import React from "react";
import img from '../asets/bb.jpg';  // Update path according to your folder structure
import'../Imagefolder/Imagefolder.css'

const Imagefolder = () => {
    return (
        <>
            <div className="img">
                <img src={img} alt="Image description" />  {/* Add alt for accessibility */}
            </div>
        </>
    );
}

export default Imagefolder;
