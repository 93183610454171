import React from "react";
import "./App.css"; // Ensure your CSS file is in the right place
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Importing components


import Home from "./Component/Home";
import PillowCover from "../src/Component/pages/PillowCover";
import Mettress from "./Component/pages/Mettress";
import ComforterSet from "./Component/pages/ComforterSet";

import Bedsheet from "./Component/pages/Badsheet";
import BedSkirt from "./Component/pages/BedSkirt";
import ZiperFitedseet from"./Component/pages/ZipperFittedseet"
import MyAccount from "./Component/pages/MyAccount";
import Login from "./Component/pages/Login";
import DropdownMenu from "./Component/DropdownMenu/DropdownMenu";
import SatinDeddingProduct from "./Component/pages/SatinDeddingProduct";

import RajveereTxttile from "./Component/pages/RajveereTxttile";
import LoginOtp from "./Component/pages/LoginOtp";


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        <Route path="/RajveereTxttile" element={<RajveereTxttile/>}/>
          <Route path="/Bedsheet" element={<Bedsheet />} />
          <Route path="/PillowCover" element={<PillowCover />} />
          <Route path="/Mettress" element={<Mettress />} />
          <Route path="BedSkirt" element={<BedSkirt />} />
          <Route path="/ComforterSet" element={<ComforterSet />} />
          <Route path="/SatinSheet" element={<SatinDeddingProduct/>}/>
          <Route path="ZiperFitedseet" element={<ZiperFitedseet />} />
          <Route path="/login" element={<Login />} />
          <Route path="/myaccount" element={<MyAccount />} />
          <Route path="/dropdownMenu" element={<DropdownMenu />} />
          <Route path="/verify-otp" element={<LoginOtp />} /> {/* Verify OTP Page */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
