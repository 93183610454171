import React from 'react';
import Slider from 'react-slick'; // Import the react-slick slider
import'./ImageSlider3.css' // Import the custom CSS for the slider
// Importing images from the 'asets' folder
import pillowImg from '../asets/pppp.webp';
import pillowImg1 from '../asets/pppp3.jpg';
import pillowImg2 from '../asets/ppp5.jpg';
import pillowImg3 from '../asets/pp6.jpg';

// Create an array of images to use in the slider
const images = [pillowImg, pillowImg1, pillowImg2, pillowImg3];

const ImageSlider3 = () => {
  // Slider settings for customization
  const settings = {
    dots: true, // Enable navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Slide transition speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay speed in milliseconds
  };

  return (
   
    <div className="slider-container">
      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index} className="slide">
            <img src={img} alt={`Slide ${index + 1}`} className="slider-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider3;
