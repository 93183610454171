// import React from 'react';
// import Footer from '../Footer/Footer';
// import Footer1 from '../Footer1/Footer1';
// import satin1 from'../asets/satin.jpg';
// import satin2 from'../asets/satin1.jpg';
// import satin3 from'../asets/satin3.webp'
// import satin4 from'../asets/satin.jpg';

// import Navbar from '../Navbar/Navbar';
// import Categories from '../Categories/Categories';
// import SatinSheet from '../SatinDedding/SatinSheet';
// function SatinDeddingProduct() {
//   const products = [
//     {
//       img: satin1,
//       name: "Utopia Bedding Waterproof Pillow Protector Zippered",
//       price: "$8.99",
//       listPrice: "$12.85",
//       rating: "★★★★☆",
//       bought: "10K+ bought in past month",
//       delivery: "Thu, Oct 24",
//     },
//     {
//       img: satin2,
//       name: "Fancy Homi 4 Packs Neutral Decorative Throw Pillow Covers",
//       price: "$16.98",
//       listPrice: "$21.23",
//       rating: "★★★★☆",
//       bought: "4K+ bought in past month",
//       delivery: "Thu, Oct 24",
//     },
//     {
//       img: satin3,
//       name: "FAUNNA Zippered Pillow Protectors Cover Soft Comfortable",
//       price: "$15.99",
//       listPrice: "$19.99",
//       rating: "★★★★☆",
//       bought: "3K+ bought in past month",
//       delivery: "Thu, Oct 24",
//     },
//     {
//       img: satin4,
//       name: "Utopia Bedding Waterproof Pillow Protector Zippered",
//       price: "$8.99",
//       listPrice: "$12.85",
//       rating: "★★★★☆",
//       bought: "10K+ bought in past month",
//       delivery: "Thu, Oct 24",
//     },
   
//   ];

//   return (
//     <>

//       <Navbar/>
//        <Categories/>
//      <SatinSheet/>
//       <h1>Recommended for You</h1>
//       <div className="product-grid">
//         {products.map((product, index) => (
//           <div className="product-card" key={index}>
//             <img src={product.img} alt={product.name} />
//             <h2>{product.name}</h2>
//             <p className="price">
//               <span className="current-price">{product.price}</span>
//               <span className="list-price">{product.listPrice}</span>
//             </p>
//             <p className="rating">{product.rating}</p>
//             <p>{product.bought}</p>
//             <p className="delivery">
//               FREE delivery <strong>{product.delivery}</strong>
//             </p>
//           </div>
//         ))}
//       </div>

//       <Footer />
//       <Footer1 />

//     </>
//   );
// }
// export default SatinDeddingProduct;

import React from 'react';
import Footer from '../Footer/Footer';
import Footer1 from '../Footer1/Footer1';
import satin1 from '../asets/satin.jpg';
import satin2 from '../asets/satin1.jpg';
import satin3 from '../asets/satin3.webp';
import satin4 from '../asets/satin.jpg';

import Navbar from '../Navbar/Navbar';
import Categories from '../Categories/Categories';
import SatinSheet from '../SatinDedding/SatinSheet';


function SatinDeddingProduct() {              // existing code
  const products = [
    {
      img: satin1,
      name: "Utopia Bedding Waterproof Pillow Protector Zippered",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
    {
      img: satin2,
      name: "Fancy Homi 4 Packs Neutral Decorative Throw Pillow Covers",
      price: "$16.98",
      listPrice: "$21.23",
      rating: "★★★★☆",
      bought: "4K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
    {
      img: satin3,
      name: "FAUNNA Zippered Pillow Protectors Cover Soft Comfortable",
      price: "$15.99",
      listPrice: "$19.99",
      rating: "★★★★☆",
      bought: "3K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
    {
      img: satin4,
      name: "Utopia Bedding Waterproof Pillow Protector Zippered",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
  ];

  return (
    <>
      <Navbar />
      <Categories />
     <SatinSheet/>

      <h1>Recommended for You</h1>
      <div className="product-grid">
        {products.map((product, index) => (
          <div className="product-card" key={index}>
            <img src={product.img} alt={product.name} />
            <h2>{product.name}</h2>
            <p className="price">
              <span className="current-price">{product.price}</span>
              <span className="list-price">{product.listPrice}</span>
            </p>
            <p className="rating">{product.rating}</p>
            <p>{product.bought}</p>
            <p className="delivery">
              FREE delivery <strong>{product.delivery}</strong>
            </p>
          </div>
        ))}
      </div>

      <Footer />
      <Footer1 />
    </>
  );
}

export default SatinDeddingProduct;  // existing code
