// Footer.js
import React from 'react';
import './Footer.css'; // Custom CSS file for footer styling

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-columns">
                <div className="footer-column">
                    <h4>Get to Know Us</h4>
                    <ul>
                        <li><a href="#">Careers</a></li>
                        <li><a href="#">Rajveer Newsletter</a></li>
                        <li><a href="#">About Rajveer</a></li>
                        <li><a href="#">Accessibility</a></li>
                        <li><a href="#">Sustainability</a></li>
                        <li><a href="#">Press Center</a></li>
                        <li><a href="#">Investor Relations</a></li>
                        <li><a href="#">Rajveer Devices</a></li>
                        <li><a href="#">Rajveer Science</a></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h4>Make Money with Us</h4>
                    <ul>
                        <li><a href="#">Sell on Rajveer</a></li>
                        <li><a href="#">Sell apps on Rajveer</a></li>
                        <li><a href="#">Supply to Rajveer</a></li>
                        <li><a href="#">Protect & Build Your Brand</a></li>
                        <li><a href="#">Become an Affiliate</a></li>
                        <li><a href="#">Become a Delivery Driver</a></li>
                        <li><a href="#">Start a Package Delivery Business</a></li>
                        <li><a href="#">Advertise Your Products</a></li>
                        <li><a href="#">Self-Publish with Us</a></li>
                        <li><a href="#">Become an Rajveer Hub Partner</a></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h4>Rajveer Payment Products</h4>
                    <ul>
                        <li><a href="#"> Visa</a></li>
                        <li><a href="#"> Store Card</a></li>
                        <li><a href="#"> Secured Card</a></li>
                        <li><a href="#"> Business Card</a></li>
                        <li><a href="#">Shop with Points</a></li>
                        <li><a href="#">Credit Card Marketplace</a></li>
                        <li><a href="#">Reload Your Balance</a></li>
                        <li><a href="#">Gift Cards</a></li>
                        <li><a href="#"> Currency Converter</a></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h4>Let Us Help You</h4>
                    <ul>
                        <li><a href="#">Your Account</a></li>
                        <li><a href="#">Your Orders</a></li>
                        <li><a href="#">Shipping Rates & Policies</a></li>
                        <li><a href="#">Prime</a></li>
                        <li><a href="#">Returns & Replacements</a></li>
                        <li><a href="#">Manage Your Content and Devices</a></li>
                        <li><a href="#">Recalls and Product Safety Alerts</a></li>
                        <li><a href="#">Registry & Gift List</a></li>
                        <li><a href="#">Help</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-logo">
                    <a href="#">Rajveer</a>
                </div>
                <div className="footer-locale">
                    <select>
                        <option>English</option>
                    </select>
                    <select>
                        <option>India</option>
                    </select>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
