import React from 'react';
import './ImageSlider1.css'; // Ensure the CSS file is in the correct location

// Import your images

import dry from '../asets/mt1.webp';
import string from '../asets/mt2.webp';
import coffee from '../asets/mt3.webp';
import treadmill from '../asets/m4.webp';
import toy from '../asets/mt5.webp';

const ImageSlider1 = () => {
  const sliderItems = [
   
    {
      img: dry,
      title: 'Utopia Bedding Quilted Fitted Mattress',
      price: 'Up to 75% Off'
    },
    {
      img: string,
      title: 'EASELAND Queen Size Mattress Pad Pillow ',
      price: 'Up to 70% Off'
    },
    {
      img: coffee,
      title: 'Mattress Topper Queen, 2 Inch Gel Memory Foam Bed Topper for Queen Size Bed',
      price: 'Up to 80% Off'
    },
    {
      img: treadmill,
      title: 'Mattress Topper Queen,Extra Thick Mattress Pad Cover for Back Pain',
      price: 'Up to 70% Off'
    },
    {
      img: toy,
      title: 'LINENSPA Memory Foam Mattress Topper - 2 Inch Gel Infused',
      price: 'Up to 70% Off'
    }
  ];

  return (
    <div className="custom-slider">
      <h2 className="custom-slider-title">HYLEORY King Mattress Pad</h2>
      <div className="custom-slider-container">
        {sliderItems.map((item, index) => (
          <div key={index} className="custom-slider-item">
            <img src={item.img} alt={item.title} className="custom-slider-image" />
            <h3 className="custom-slider-item-title">{item.title}</h3>
            <p className="custom-slider-item-price">{item.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider1;
