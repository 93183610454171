


import React,{ useState } from 'react';
import './MyAccount.css';
import img from '../asets/f.png';
import Navbar from '../Navbar/Navbar';
import Categories from '../Categories/Categories';
import Footer1 from '../Footer1/Footer1';
import Footer from '../Footer/Footer';
import {useNavigate} from "react-router-dom";

const MyAccount = () => {

    const [contact, setContact] = useState(''); // State to store email or mobile number
    const [errorMessage, setErrorMessage] = useState(''); // State to store validation errors or backend responses
    const navigate = useNavigate();
    const handleSubmit = async () => {
        if (!contact) {
            setErrorMessage('Please enter a valid email or mobile number.');
            return;
        }

        try {
            const response = await fetch('http://localhost:8080/v1/user/verification', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email:contact }),
            });

            const data = await response.json();

            if (data.status==='SUCCESS') {
                alert('OTP sent successfully!');
                // Navigate to the Verify OTP page and pass the mobile number as state
                navigate('/verify-otp', { state: { contact } });
            } else {
                alert(data.message);
                setErrorMessage(data.message || 'Failed to send OTP. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
            console.error('Error:', error);
        }
    };

    return (
    <div>
      <Navbar />
      <Categories/>
      <div className="myaccount-container">
        <div className="left-section">
          <h1>My Account</h1>
          <h2>Get access to your Orders, Wishlist and Recommendations</h2>
          <div className="image-placeholder">
            <img src={img} alt="My Account Illustration" style={{ width: '90%', height: '100%', objectFit: 'contain' }} />
          </div>
        </div>
        <div className="right-section">
          <input
            type="text"
            placeholder="Enter Email/Mobile number"
            className="input-field"
            value={contact}
            onChange={(e) => setContact(e.target.value)} // Update state on input change
          />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          <p>

            By continuing, you agree to MyShop <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.
          </p>
          <button className="request-otp-button" onClick={handleSubmit}>Request OTP</button>
          <p className="create-account-link">
            New to MyShop? <a href="#">Create an account</a>
          </p>
        </div>
      </div>
      <Footer/>
      <Footer1/>
    </div>
    
  );
};

export default MyAccount;
