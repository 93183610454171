import React from 'react';
import '../ProductCarousel/ProductCarousel.css';

// Import your images
import tcs from'../asets/pillo5.jpg'  // Update with the correct relative path
import speaker from'../asets/pillo.jpg'
import watch from'../asets/pillo1.jpg';
import game from'../asets/pillo4.jpg';
import  bestshaver  from'../asets/pillo3.jpg'
import airdo from'../asets/pillo2.jpg';

const products = [
  {
    id: 1,
    image: tcs, // Use the imported image here
    title: 'Sheets & Pillowcases',
    offer: 'Shop Now',
  },
  {
    id: 2,
    image: speaker,
    title: 'Pillows',
    offer: 'Shop Now',
  },
  {
    id: 3,
    image: watch,
    title: 'Pillows',
    offer: 'Shop Now',
  },
  {
    id: 4,
    image: game,
    title: 'Sheets & Pillowcases',
    offer: 'Shop Now',
  },
  {
    id: 5,
    image:  bestshaver ,
    title: ' Kids Badding ',
    offer: 'Shop Now',
  },
  {
    id: 6,
    image: airdo,
    title: 'Quilts',
    offer: 'Shop Now',
  },
];

const ProductCarousel = () => {
  return (
    <div className="carousel-container">
      <h2>Bedding & bath</h2>
      <div className="product-carousel">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.title} />
            <h3>{product.title}</h3>
            <p>{product.offer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductCarousel;
