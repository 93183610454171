import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaSearch, FaShoppingCart, FaUser } from "react-icons/fa";
import "../Navbar/Navbar.css";
import logo from "../asets/logo1.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img className="logo" src={logo} alt="MY SHOPPING" />
        <span className="explore">
          Explore <span className="plus">Plus</span>
        </span>
      </div>



      <div className="navbar-center">
        <input type="text" placeholder="Search for Products, Brands and More" />
        <button type="submit">
          <FaSearch />
        </button>
      </div>

      <Link to="/login" className="login">
           Login
        </Link>


      <div className="navbar-right">
        <Dropdown
          show={showDropdown}
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
          style={{ zIndex: 1000 }}
        >
          <Dropdown.Toggle as={Link} to="/MyAccount" className="My">
            <FaUser /> My Account
          </Dropdown.Toggle>
        </Dropdown>

        <Link to="/#Cart" className="Cart">
          <FaShoppingCart /> Cart
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
