import React from 'react';
import './BedSkirt.css'; // Make sure you have the CSS file for styling
import bedsheet1 from '../asets/bedsheet1.jpg';
import bedsheet2 from '../asets/bedsheet10.jpg';
import bedsheet3 from '../asets/bedsheet3.jpg';
import bedsheet4 from '../asets/bedsheet4.jpg';
import Footer from '../Footer/Footer';
import Footer1 from '../Footer1/Footer1';
import BedSkritt from '../BedSkrit/BedSkritt';
import Navbar from '../Navbar/Navbar';
import Categories from '../Categories/Categories';
// import ShareButton from '../ShareButton/ShareButton';

function BedSkirt() {
  const products = [
    {
      img: bedsheet1,
      name: "Utopia Bedding Waterproof Pillow Protector Zippered",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
    {
      img: bedsheet2,
      name: "Fancy Homi 4 Packs Neutral Decorative Throw Pillow Covers",
      price: "$16.98",
      listPrice: "$21.23",
      rating: "★★★★☆",
      bought: "4K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
    {
      img: bedsheet3,
      name: "FAUNNA Zippered Pillow Protectors Cover Soft Comfortable",
      price: "$15.99",
      listPrice: "$19.99",
      rating: "★★★★☆",
      bought: "3K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
    {
      img: bedsheet4,
      name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
      price: "$14.99",
      listPrice: "$24.99",
      rating: "★★★★☆",
      bought: "2K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
  ];

  return (
    <div>
       <Navbar/>
       <Categories/>
      <h1>WEL-COME TO RAJVEER TEXTILE</h1>
   <BedSkritt/>
      <h1>Recommended for You</h1>
      <div className="product-grid">
        {products.map((product, index) => (
          <div className="product-card" key={index}>
            <img src={product.img} alt={product.name} />
            <h2>{product.name}</h2>
            <p className="price">
              <span className="current-price">{product.price}</span>
              <span className="list-price">{product.listPrice}</span>
            </p>
            <p className="rating">{product.rating}</p>
            <p>{product.bought}</p>
            <p className="delivery">
              FREE delivery <strong>{product.delivery}</strong>
            </p>
          </div>
        ))}
      </div>
      {/* <ShareButton /> */}
      <Footer />
      <Footer1 />
    </div>
  );
}

export default BedSkirt;
