import { Link } from 'react-router-dom';
import '../Categories/Categories.css'
// import bed from'../asets/download.jpg'
const Categories = () => {
  const categories  = [
    { name: 'RajveereTxttile', icon:'',  path:'/RajveereTxttile'},
    { name: 'Bedsheet', icon: '', path:'/Bedsheet'},
    { name: 'PillowCover', icon: '', path:'/PillowCover'},
    { name: 'Mettress', icon: '', path:'/Mettress'},
    { name: 'BedSkirt', icon: '', path:'/BedSkirt'},
    { name: 'ComforterSet', icon: '', path:'/ComforterSet'},
    { name: 'SatinSheet', icon: '', path:'/SatinSheet'},
    { name: 'ZiperFitedseet', icon: '', path:'/ZiperFitedseet'},

  ];

  return (
    <div className="categories">
      {categories.map((category, index) => (
        <div key={index} className="category-item">
          {category?.path ? (
            <Link to={category.path}>
              <span className="category-icon">{category.icon}</span>
              <span className="category-name">{category.name}</span>
            </Link>
          ) : (
            <div>
              <span className="category-icon">{category.icon}</span>
              <span className="category-name">{category.name}</span>
            </div>
          )}
        </div>
      ))}
    </div>
 
  );
};

export default Categories;
