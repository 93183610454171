import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';  // You can create a separate CSS file to style the components
import loginimg from'../asets/login.webp'
import  Categories from'../Categories/Categories'
import Navbar from '../Navbar/Navbar';
const Login = () => {

  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setMobileNumber(e.target.value);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Handle login/signup logic here
    console.log("Mobile Number:", mobileNumber);

    try {
      // Replace with your actual API URL
      const response = await axios.post('http://localhost:8080/v1/user/verification/signin', {
        email: mobileNumber, // Payload to send to backend
      });

      console.log(response);
      // Navigate to the Verify OTP page and pass the mobile number as state
      navigate('/verify-otp', { state: { mobileNumber } });

      // Assuming your backend returns a success message
      setSuccess(response.data.message || 'Login successful!');
    } catch (err) {
      // Handle error response
      setError(err.response?.data?.message || 'Something went wrong. Please try again.');
    }

  };

  return (

<div className='main' >
<Navbar/>
<Categories/>
    <div className="login-container">
        

      {/* Offer Banner */}
      <div className="offer-banner">
        <div className="offer-details">
     
          {/* <h2>FLAT ₹400 OFF</h2>
          <p>+ Free Shipping On 1st Order</p>
          <div className="offer-code">
            <span>Code:</span> <strong>MYNTRA400</strong>
          </div> */}
        </div>
       
        <div className="loginimg">
    
          {/* Add image of the excited character */}
          <img src={loginimg} alt="offer-character"  style={{width:390,   height: 200} }/>
        </div>
      </div>

      {/* Login Form */}
      <div className="login-form">
        <h3>Login or Signup</h3>
        <form onSubmit={handleSubmit}>
          <label htmlFor="mobile">Mobile Number</label>
          <div className="mobile-input">
            {/*<span>+91</span>*/}
            <input
              type="tel"
              id="mobile"
              value={mobileNumber}
              onChange={handleInputChange}
              required
              placeholder="Enter Email/Mobile number"
            />
          </div>
          <p>
            By continuing, I agree to the{' '}
            <a href="#">Terms of Use</a> & <a href="#">Privacy Policy</a>
          </p>
          <button type="submit" className="continue-btn">
            Continue
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        <p>
          Have trouble logging in? <a href="#">Get help</a>
        </p>
      </div>
    </div>
    </div>
  );
};

export default Login;
