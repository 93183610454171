

import React from 'react';
import './PillowCover.css'; // Ensure the correct file extension is included
import pillowImg from '../asets/p.jpg';
import pillowImg1 from '../asets/pp2.webp';
import pillowImg2 from '../asets/bs3.webp';
import pillowImg3 from '../asets/pp4.webp';
import pillowImg4 from '../asets/pp2.webp'
import pillowImg5 from '../asets/pp3.webp'
import pillowImg6 from '../asets/se7.jpg'
import pillowImg7 from '../asets/sec.jpg'
import pillowImg8 from '../asets/sec2.webp'
import pillowImg9 from '../asets/sec5.jpg'
import pillowImg10 from '../asets/sec6.jpg'
import pillowImg11 from '../asets/sec5.jpg'
import pillowImg12 from '../asets/sec2.webp'
import pillowImg13 from '../asets/sec4.webp'
import pillowImg14 from '../asets/sec2.webp'
import pillowImg15 from '../asets/sec6.jpg'
import ImageSlider3 from '../ImageSlider3/ImageSlider3';
import Imagefolder from '../Imagefolder/Imagefolder';
import Footer from '../Footer/Footer';
import Footer1 from '../Footer1/Footer1';
import Navbar from '../Navbar/Navbar';
import Categories from '../Categories/Categories';







const products = [


  {
    img: pillowImg,
    name: "Utopia Bedding Waterproof Pillow Protector Zippered",
    price: "$8.99",
    listPrice: "$12.85",
    rating: "★★★★☆",
    bought: "10K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  {
    img: pillowImg1,
    name: "Fancy Homi 4 Packs Neutral Decorative Throw Pillow Covers",
    price: "$16.98",
    listPrice: "$21.23",
    rating: "★★★★☆",
    bought: "4K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  {
    img: pillowImg2,
    name: "FAUNNA Zippered Pillow Protectors Cover Soft Comfortable",
    price: "$15.99",
    listPrice: "$19.99",
    rating: "★★★★☆",
    bought: "3K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  {
    img: pillowImg3,
    name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },

  {
    img: pillowImg4,
    name: "decorUhome Christmas Decorative Throw Pillow Covers",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  {
    img: pillowImg5,
    name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  {
    img: pillowImg6,
    name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  {
    img: pillowImg7,
    name: "opfinel Set of 2 Rust Decorative Throw Pillow Covers ",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },

  {
    img: pillowImg8,
    name: "decorUhome Faux Fur Throw Pillow Covers",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  {
    img: pillowImg9,
    name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  } ,
{
    img: pillowImg10,
    name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  {
    img: pillowImg11,
    name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },

  {
    img: pillowImg12,
    name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  {
    img: pillowImg13,
    name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  {
    img: pillowImg14,
    name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },
  
  {
    img: pillowImg15,
    name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
    price: "$14.99",
    listPrice: "$24.99",
    rating: "★★★★☆",
    bought: "2K+ bought in past month",
    delivery: "Thu, Oct 24"
  },

];

const PillowCover = () => {
  return (
   
    <div>
  <Navbar/>
  <Categories/>
  <ImageSlider3/>

  <h1>Based on your recent shopping trends</h1>

      <div className="product-grid">
        {products.map((product, index) => (
          <div className="product-card" key={index}>
            <img src={product.img} alt={product.name} />
            <h2>{product.name}</h2>
            <p className="price">
              <span className="current-price">{product.price}</span>
              <span className="list-price">{product.listPrice}</span>
            </p>
            <p className="rating">{product.rating}</p>
            <p>{product.bought}</p>
            <p className="delivery">FREE delivery <strong>{product.delivery}</strong></p>

          </div>
       
        ))}
      </div>
    <Imagefolder/>
    <Footer/>
    <Footer1/>

    </div>


  );
};

export default PillowCover;

