import React from 'react';
import './ImageComponent.css'; // Optional: for custom styling
import im from'../asets/saruk.jpg'
const ImageComponent = () => {
  return (
    <div className="image-container">
      <img
        src={im} 
        alt="Deals Banner"
        className="banner-image"
      />
    </div>
  );
};

export default ImageComponent;
