import React from 'react';
import './Footer1.css';

const Footer1 = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-column">
          <h3>Bedsheet</h3>
          <p>Stream millions of Bedsheet</p>
          <h3> Business</h3>
          <p>Everything For Your Business</p>
          <h3>Goodreads</h3>
          <p>Bedsheet with cover</p>
          <h3> Resale</h3>
          <p>Great Deals on Quality Used Products</p>
        </div>
        <div className="footer-column">
          <h3>Pillow cover</h3>
          <p>Reach customers wherever they spend their time</p>
          <h3>Cortan pillow cover</h3>
          <p>Mettress</p>
          <h3> comfort Mettress</h3>
          <p>Mettress</p>
        </div>
        <div className="footer-column">
          <h3>6pm</h3>
          <p>Score deals on fashion brands</p>
          <h3>Global</h3>
          <p>Ship Orders Internationally</p>
          <h3>Mettress</h3>
          <p>Get Info cover</p>
          <h3>Mettress</h3>
          <p>Mettress</p>
        </div>
        <div className="footer-column">
          <h3>Mettress</h3>
          <p>art & collectibles</p>
          <h3>Home Services</h3>
          <p>Experienced Happiness Guarantee</p>
          <h3>Kindle Direct Publishing</h3>
          <p>Indie Digital & Print Publishing Made Easy</p>
          <h3>Mettress</h3>
          <p>Bedsheet</p>
        </div>
        <div className="footer-column">
          <h3>Bedsheet</h3>
          <p>cover Publishing Made Easy</p>
          <h3>Web Services</h3>
          <p>Scalable Cloud Computing Services</p>
          <h3> Photos</h3>
          <p>Bedsheet</p>
          <h3>covers</h3>
          <p>Smart Home </p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Conditions of Use | Privacy Notice | Consumer Health Data Privacy Disclosure | Your Ads Privacy Choices</p>
        <p>&copy; 1998-2024, Rajveer.com, Inc. or its affiliates</p>
      </div>
    </footer>
  );
};

export default Footer1;
