import React from 'react';
import './RajveerTexttile.css'; // Custom CSS for styling
import homebedshit from "../asets/bedsheet1.jpg";
import homebedshit1 from "../asets/bedsheet12.jpg";
import homebedshit2 from "../asets/pillo3.jpg";
import homebedshit3 from "../asets/pillo5.jpg";
import pillowImg1 from '../asets/pillo4.jpg';
import pillowImg2 from '../asets/bs3.webp';
import pillowImg3 from '../asets/pp4.webp';
import pillowImg4 from '../asets/pillo1.jpg'
import mattress1 from '../asets/satin1.jpg'; 
import mattress2 from '../asets/mettress4.jpg'; 

import Navbar from '../Navbar/Navbar';
import Categories from '../Categories/Categories';
import ImageSlider from '../ImageSlider/ImageSlider';
import Footer from '../Footer/Footer';
import Footer1 from '../Footer1/Footer1';
import ImageComponent from '../ImageComponent/ImageComponent';

const items = [
  {
    img: homebedshit,
    title: 'Printed',
    subtitle: 'Printed Cushion',
  },
  {
    img: homebedshit1,
    title: 'Foil',
    subtitle: 'Foil Cushion',
  },
  {
    img: homebedshit2,
    title: 'Smoking',
    subtitle: 'Smoking Cushion',
  },
  {
    img: homebedshit3,
    title: 'Macrame',
    subtitle: 'Macrame Cushion',
  },
  {
    img:pillowImg1,
    title: 'Printed',
    subtitle: 'Printed Cushion',
  },
  {
    img: pillowImg2,
    title: 'Foil',
    subtitle: 'Foil Cushion',
  },
  {
    img: pillowImg3,
    title: 'Smoking',
    subtitle: 'Smoking Cushion',
  },
  {
    img: pillowImg4,
    title: 'Macrame',
    subtitle: 'Macrame Cushion',
  },
  {
    img: mattress1,
    title: 'Printed',
    subtitle: 'Printed Cushion',
  },
  {
    img: mattress2,
    title: 'Foil',
    subtitle: 'Foil Cushion',
  },
  
  

];

function RajveerTexttile() {
  return (
    <>
      <Navbar />
      <Categories />
      <ImageSlider />
      <div className="cushion-items">
      
        {items.map((item, index) => (
          <div className="items-card" key={index}>
            <img src={item.img} alt={item.title} className="items-image" />
            <h2 className="items-title">{item.title}</h2>
            <p className="items-subtitle">{item.subtitle}</p>
            <button className="view-all-button">VIEW ALL</button>
          </div>
        ))}
      </div>
  <ImageComponent/>
      
      <Footer/>
    <Footer1/>
    </>
  );
}

export default RajveerTexttile;
