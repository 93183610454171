import React from 'react';
import'./ZipperFittedseet';
import Zip1 from '../asets/mt1.webp'; 
import Zip2 from '../asets/mt2.webp'; 
import Zip3 from '../asets/mt1.webp'; 
import Zip4 from '../asets/mt3.webp'; 
import Zip5 from '../asets/mt5.webp'; 
import Zip6 from '../asets/MT.jpg'; 
import Zip7 from '../asets/MT2.jpg'; 
import Zip8 from '../asets/MT3.jpg'; 
import Zip9 from '../asets/MT4.jpg'; 
import Zip10 from '../asets/MT5.jpg'; 
import Zip11 from '../asets/MT6.jpg'; 
import Zip12 from '../asets/MT7.jpg'; 
import Footer1 from '../Footer1/Footer1';
import Footer from '../Footer/Footer';
import Zipper from '../Zipper/Zipper';
import Navbar from '../Navbar/Navbar';
import Categories from '../Categories/Categories';




function ZipperFittedseet() {
  const products = [
    {
      img: Zip1,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
      
   
    },
    {
      img:Zip2,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img:Zip3,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img: Zip4,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img:Zip5,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
  
    {
      img:Zip6,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img: Zip7,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img: Zip8,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img: Zip9,
      name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
      price: "$14.99",
      listPrice: "$24.99",
      rating: "★★★★☆",
      bought: "2K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
    {
      img: Zip10,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img: Zip11,
      name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
      price: "$14.99",
      listPrice: "$24.99",
      rating: "★★★★☆",
      bought: "2K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
    {
      img: Zip12,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
  ];

  return (
    <div>
    
      <Navbar/>
      <Categories/>
      <Zipper/>
      <h1>Based on your recent shopping trends</h1>
      <div className="product-grid">
        {products.map((product, index) => (
          <div className="product-card" key={index}>
            <img src={product.img} alt={product.name} />
            <h2>{product.name}</h2>
            <p className="price">
              <span className="current-price">{product.price}</span>
              <span className="list-price">{product.listPrice}</span>
            </p>
            <p className="rating">{product.rating}</p>
            <p>{product.bought}</p>
            <p className="delivery">
              FREE delivery <strong>{product.delivery}</strong>
              <button className="add-to-cart">Add to Cart</button>
            </p>
          </div>
        ))}
      </div>
      <Footer/>
    <Footer1/>
    </div>

  );
}

export default ZipperFittedseet;
