
import React, { useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import"./LoginOtp.css";


const LoginOtp = () => {
  const { state } = useLocation();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const phoneOrEmail = state?.mobileNumber || ''; // Retrieved from navigation state
    const navigate = useNavigate();

  const handleVerifyOtp = async () => {



      // Prepare data to send to the backend
      const payload = {
          otp: otp,
          // mobileNumber: phoneOrEmail, // Send the mobile number as well
      };

      try {
          // Make an API call to verify the OTP
          const response = await fetch('http://localhost:8080/v1/user/verification/code', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
              credentials: 'include', // Ensures cookies are included in the request
          });

          const data = await response.json();

          if (response.ok) {
              alert('SUCCESS'); // Show success message
              // Navigate to the Verify OTP page and pass the mobile number as state
              navigate('/RajveereTxttile', { state: { otp } });
          } else {
              setError(data.message); // Show error message from the backend
          }
      } catch (err) {
          setError('An error occurred. Please try again later.');
      }

    // if (otp === '123456') { // Mock OTP for demo
    //   alert('OTP verified successfully!');
    // } else {
    //   setError('Invalid OTP. Please try again.');
    // }
  };

  return (
    <div className="login-otp">
      <h2>Verify OTP</h2>
      <p>OTP sent to {phoneOrEmail}</p>
      <input
        type="text"
        placeholder="Enter OTP"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        maxLength={6}
      />
      <button onClick={handleVerifyOtp}>Verify OTP</button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default LoginOtp;
