import React from 'react';
import Slider from 'react-slick';  

import pillowImg from '../asets/comfort.jpg';
import pillowImg1 from '../asets/comfort.jpg';

const images = [pillowImg, pillowImg1,];

const Comfort = () => {
  
  const settings = {
    dots: true, 
    infinite: true, 
    speed: 500, 
    slidesToShow: 1, 
    slidesToScroll: 1, 
    autoplaySpeed: 3000, 
  };

  return (
   
    <div className="slider-container">
      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index} className="slide">
            <img src={img} alt={`Slide ${index + 1}`} className="slider-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Comfort;
