import React from "react";
import Slider from "react-slick";
import '../ImageSlider/ImageSlider.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import bnner from'../asets/main1.webp' ; 
import bnner1 from'../asets/main3.webp';
import bnner2 from'../asets/main4.webp';

import bnner3 from'../asets/main4.webp';


const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const slides = [
    {
      image: bnner ,  // Local image
      // title: "Personal Care - Min. 40% Off",
      // description: "Vaseline, Garnier & more - Lowest Price Today!",
      // bankOffers: "Axis Bank, ICICI, Yes Bank - 10% Instant Discount"
    },
    {
      image: bnner2, 
      // title: "Fashion Deals - Min. 60% Off",
      // description: "Top brands like Levi's, UCB & more",
      // bankOffers: "HDFC, SBI - 5% Cashback"
    },
    {
      image: bnner3, 
      // title: "Fashion Deals - Min. 60% Off",
      // description: "Top brands like Levi's, UCB & more",
      // bankOffers: "HDFC, SBI - 5% Cashback"
    },
    {
      image: bnner1, 
      // title: "Fashion Deals - Min. 60% Off",
      // description: "Top brands like Levi's, UCB & more",
      // bankOffers: "HDFC, SBI - 5% Cashback"
    },
    // You can add more slides as needed
  ];

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slider-item">
            <img src={slide.image} alt={slide.title} className="slider-image" />
            <div className="slider-content">
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
              <span className="bank-offers">{slide.bankOffers}</span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
