import React from 'react';
import Slider from 'react-slick'; 
import satinbg1 from '../asets/satinbg2.jpg';
import satinbg2 from '../asets/satinbg3.jpg';



const images = [ satinbg1, satinbg2, ];

const SatinSheet = () => {
  
  const settings = {
    dots: true, 
    infinite: true, 
    speed: 500, 
    slidesToShow: 1, 
    slidesToScroll: 1, 
    autoplay: true, 
    autoplaySpeed: 3000, 
  };

  return (
<>
    <div className="slider-container">

      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index} className="slide">
            <img src={img} alt={`Slide ${index + 1}`} className="slider-image" />
          </div>
        ))}
      </Slider>
    </div>
    </>
  );
};

export default SatinSheet;
