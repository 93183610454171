import React from 'react'; 
import '../pages/Mettress.css';
import mattress1 from '../asets/metrees.jpg'; 
import mattress2 from '../asets/mettress4.jpg'; 
import mattress3 from '../asets/metrees.jpg'; 
import mattress4 from '../asets/metrees.jpg'; 
import mattress5 from '../asets/mettrees5.jpg'; 
import mattress6 from '../asets/mettress2.jpg'; 
import mattress7 from '../asets/mettress3.jpg'; 
import mattress8 from '../asets/mettress4.jpg'; 
import mattress9 from '../asets/mettress6.jpg'; 
import mattress10 from '../asets/mettress6.jpg'; 
import mattress11 from '../asets/metrees.jpg'; 
import mattress12 from '../asets/mettress3.jpg'; 
import Mettressslider from '../Mettressslider/Mettressslider';
import Footer1 from '../Footer1/Footer1';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import Categories from '../Categories/Categories';

function Mettress() {
  const products = [
    {
      img: mattress1,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
      
   
    },
    {
      img:mattress2,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img:mattress3,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img: mattress4,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img:mattress5,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
  
    {
      img:mattress6,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img: mattress7,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img: mattress8,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img: mattress9,
      name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
      price: "$14.99",
      listPrice: "$24.99",
      rating: "★★★★☆",
      bought: "2K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
    {
      img: mattress10,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
    {
      img: mattress11,
      name: "MIULEE Corduroy Pillow Covers with Splicing Set of 2",
      price: "$14.99",
      listPrice: "$24.99",
      rating: "★★★★☆",
      bought: "2K+ bought in past month",
      delivery: "Thu, Oct 24",
    },
    {
      img: mattress12,
      name: "RAJVEER TEXTILE King Size Zipper Fitted Sheet",
      price: "$8.99",
      listPrice: "$12.85",
      rating: "★★★★☆",
      bought: "10K+ bought in past month",
      delivery: "Prime Delivery",
    },
  ];

  return (
    <div>
        <Navbar/>
        <Categories/>
      <Mettressslider/>
      <div className="product-grid">
        {products.map((product, index) => (
          <div className="product-card" key={index}>
            <img src={product.img} alt={product.name} />
            <h2>{product.name}</h2>
            <p className="price">
              <span className="current-price">{product.price}</span>
              <span className="list-price">{product.listPrice}</span>
            </p>
            <p className="rating">{product.rating}</p>
            <p>{product.bought}</p>
            <p className="delivery">
              FREE delivery <strong>{product.delivery}</strong>
              <button className="add-to-cart">Add to Cart</button>
            </p>
          </div>
        ))}
      </div>
      <Footer/>
    <Footer1/>
    </div>

  );
}

export default Mettress;
