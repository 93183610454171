import React from 'react';
import './Banner.css'; // Import the CSS file
import Bedsheet from'../asets/bs.webp'
import Pillowcover from'../asets/bs2.webp'
import Pillowcover1 from'../asets/bs3.webp'
import Pillowcover2 from'../asets/bs5.webp'

const Banner = () => {
  return (
    <div className="banner-container">
      <h2>Brand in this category</h2>
      <div className="banner-grid">
        <div className="banner-item">
          <img src={Bedsheet} alt="Clothing" />
          <p>Bedsheet</p>
          <span>Under ₹499</span>
        </div>
        <div className="banner-item">
          <img src={Pillowcover} alt="Footwear" />
          <p>Pillowcover</p>
          <span>Under ₹499</span>
        </div>
        <div className="banner-item">
          <img src={Pillowcover1} alt="Beauty and Makeup" />
          <p>Pillowcove</p>
          <span>Under ₹499</span>
        </div>
        <div className="banner-item">
          <img src={Pillowcover2} alt="Bags" />
          <p>Bedsheet</p>
          <span>Under ₹699</span>
        </div>
      
     
        
        
     
      
       
      </div>
    </div>
  );
};

export default Banner;
