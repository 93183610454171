import React from "react";
import'./BedSkritt.css'
import im from'../asets/bedskript.jpg'
const BedSkritt=()=>{
    return(
        <>
          <div className="image-container">
      <img
        src={im} 
        alt="Deals Banner"
        className="banner-image"
      />
    </div>
        </>
    );
}
export default BedSkritt;