import React from "react";
import Navbar from "./Navbar/Navbar";
import Categories from "./Categories/Categories";
import ImageSlider from "./ImageSlider/ImageSlider";
import ProductCarousel from "./ProductCarousel/ProductCarousel";
import ImageSlider1 from "./ImageSlider1/ImageSlider1";
import ImageComponent from "./ImageComponent/ImageComponent";
import Banner from "./Banner/Banner";
import DealsBanner from "./DealsBanner/DealsBanner";

const Home = () => {
    return (
        <>
            <Navbar />
            <Categories />
            <ImageSlider />
            <ProductCarousel />
            <ImageSlider1 />
            <ImageComponent />
            <Banner />
            {/* <DealsBanner /> */}

            
            
         
        </>
    )
}
export default Home;